@media only screen and (max-width: 600px) {
    .menu_item_card span {display: none;}
}

.main_menu_cards a {}

.main_menu_cards
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;

}
    

.menu_item_card
{
    min-width: 265px;
    background-color: #4cc1bd;
    opacity: 0.8;    
    color:  white;
    border-radius: 25px;
    border: 0px;
    font-size: 26px;
    margin: 20px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
/*    font-family: muli !important; */
    animation: 0.9s ease-out 0s 1 menufader;
    align-items: center;

}


.menu_item_card img
{
    margin: 5px;
    height: 61px;
    width: 65px;
}