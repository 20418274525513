:root {

  --clr-primary: #4cc1bd;
  --clr-secondary: #4cc1bd;
  --clr-neutral: white;
  
  --ff-primary: Arial, Helvetica, sans-serif;

}

.bgtest {
  border: 1px solid black;
}

*, 
*::before,
*::after {
  box-sizing: border-box;
}

@media (min-width: 40em) {
  /* for demonstration but use this for tailoring to laptop screen else developing for mobile first */
  body {
    background-color: green;
  }
}

body {
  font-family: var(--ff-primary);
  font-weight: 400;
  font-size: 1.3125rem;
  line-height: 1.6;
}

header {
  background-color: var(--clr-primary);
  height: 50px;
  color: white;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
header * { width: 33%;}

header img {
  
  width: 60%;
}
main {
  margin-top: 50px;
  margin-inline: auto;
  width: min(90%, 70.5rem);
}

footer {
  background-color: var(--clr-primary);
  height: 50px;
  color: var(--clr-neutral);

  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

img {
  max-width: 100%;
}

.margin-inline-auto { margin-inline: auto; }

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.width-full { width: 100%; }

.justify-between { justify-content: space-between;}

/* custom radio button accents */

input[type="radio"],
input[type="checkbox"] {
  width: 3em;
  height: 3rem;
  accent-color: var(--clr-primary);
}

.avatar {
  vertical-align: middle;
  width: 5em;
  height: 4em;
  border-radius: 50%;
  border: 3px solid var(--clr-primary);
  background-color: var(--clr-primary);
}


.resident {
  padding: 0.5em;
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid lightgray;
  
}
.resident * { 
  align-self: center; 
  color: var(--clr-primary);
  font-weight: 500;
}
.resident img { margin-right: 1.5em;  }

.section {
  border-bottom: 1px solid lightgray;
  cursor: pointer;
}

.sectionHeader {
  display: flex;
  flex-direction: row;
  padding: 20px;
}

.sectionHeaderIcon {
  width: 100px;
}

.sectionHeaderIcon img {
  width: 50px;
}

.sectionHeaderExpand {
  width: 100px;
}

.sectionHeaderContent{
  color: #4cc1bd;
  font-weight: 500;
  align-self: center;
}

.careplan_section_image_circle{
  background-color: #4cc1bd;
}

.careplan_sub_section{ 
  margin: 20px;
  border-bottom: 0.5px solid #4cc1bd;
}
.careplan-white { 
  color: grey; 
}
.careplan-grey {
  color: grey;
}

.careplan_entry{
  display: flex;
  flex-direction: column;
  margin: 5px;
}

.careplan_entry_row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
}

.toggle_border_left {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.toggle_border_right {
border-bottom-right-radius: 5px;
border-top-right-radius: 5px;
}
.toggle_nagative{
  background-color: red;
}
.toggle_question{background-color: rgba(248,173,64,.7);}
.toggle_positive{
  background-color: rgba(146,200,102,.7);
}
.toggle_grey{background-color: rgba(144,146,149,.7) !important}
.toggle_container{
  display: flex;
  flex-direction: row;
}

.toggle_container div img {
  width: 26px;
}

.careplan_comments{
  width: 100%;
  background-color: #dcf5f3;
  color: #4cc1bd;
}
  
.careplan_comments:hover {
  background-color: #4cc1bd;
  color: #dcf5f3;
}

.careplan_subsection {
  color: #4cc1bd;
  font-weight: 500;
}

.careplan_total {
  display: grid;
  grid-template-columns: auto 50px;
  color: #4cc1bd;
  font-weight: 650;
}

.careplan_meal_entry{
  width: 60px;
  text-align: right;
  border: 1px solid #4cc1bd;
  border-radius: 3px;
}

.careplan_liquids_entry{
  width: 60px;
  text-align: right;
  border: 1px solid #4cc1bd;
  border-radius: 3px;
  width: 100%;
  background-color: #dcf5f3;
  color: #4cc1bd;
  padding:2px
}
  
.careplan_liquids_entry:hover {
  background-color: #4cc1bd;
  color: #dcf5f3;
}

.careplan_meals_entry{
  width: 60px;
  text-align: right;
  border: 1px solid #4cc1bd;
  border-radius: 3px;
  width: 100%;
  background-color: #dcf5f3;
  color: #4cc1bd;
  padding:2px
}
  
.careplan_meals_entry:hover {
  background-color: #4cc1bd;
  color: #dcf5f3;
}


.careplan_select {
  width: 60px;
  text-align: right;
  border: 1px solid #4cc1bd;
  border-radius: 3px;
  width: 100%;
  background-color: #dcf5f3;
  color: #4cc1bd;
  padding:2px
}
  
.careplan_select:hover {
  background-color: #4cc1bd;
  color: #dcf5f3;
}


.careplan_text_input{
  border:0;
  color: grey;
}

.careplan_text_input:hover {
  background-color: #4cc1bd;
  color: #dcf5f3;
}

.signinLogo {
  margin-top: 30px;
  width: 40%;
  align-items: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.loginObjects {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.bg-aqPrimary{ background-color: #4cc1bd;}